import { Box, Grid, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { IconCaretDownFilled, IconDotsVertical, IconLine } from "@tabler/icons-react";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { mergeRefs } from "react-merge-refs";
import { useDispatch, useSelector } from "react-redux";
import { setViewBuilder } from "src/features/builder/builderSlice";
import { selectBuilderSettings, selectComponentById } from "src/features/builder/selectors";
import store from "src/store/store";
import { isPartOfModalComponent } from "src/utils/generalUtils";
import ActionPopover from "./ActionPopover";
import { DIRECTION, moveBlocks } from "./ComponentsTreeOperations";
import { ComponentsTreeItemProps } from "./TreeTypes";

const componentsTreeItemStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    margin: "4px 0",
    padding: "5px",
    cursor: "pointer",
    opacity: 1,
    border: "none",
    transition: "background-color 0.3s ease",
  },
};

const ComponentsTreeItem = memo(
  ({
    componentItemId,
    handleDeleteItem,
    handleCopyItems,
    children,
    handlePasteItems,
    view,
    isCanvasClicked,
    setIsCanvasClicked,
    handleSelectComponent,
    handleSelectComponentSingleAndMulti,
    isOpen,
    handleToggleOpen,
    copyItemsRef,
    level = 0,
  }: ComponentsTreeItemProps & { level: number }) => {
    const componentItem = useSelector(state => selectComponentById(state, componentItemId));
    const componentItemChildren = useMemo(() => {
      return componentItem?.children;
    }, [componentItem?.children]);
    const modalsConfig = useSelector((state: any) => state.builder.modalsConfig?.modals);
    const hasActiveModal = useMemo(() => (modalsConfig || []).some((modal: any) => modal?.isActive), [modalsConfig]);

    // Filter children based on modal state
    const filteredChildren = useMemo(() => {
      if (!componentItemChildren) return componentItemChildren;

      return componentItemChildren.filter((childId: any) => {
        const childElement = store.getState().builder.entities[childId];
        const entities = store.getState().builder.entities;
        if (hasActiveModal) {
          // When modal is active:
          // 1. Show modal components
          // 2. Show children of modal components
          // 3. Show components that are part of a modal component's tree
          return childElement?.isModalComponent || isPartOfModalComponent(childId, entities) || componentItem?.isModalComponent;
        } else {
          // When no modal is active, show only non-modal components
          return !childElement?.isModalComponent;
        }
      });
    }, [componentItemChildren, hasActiveModal, componentItem?.isModalComponent]);

    const memoizedChildren = useMemo(() => children, [children]);
    const activeComponentId = useSelector((state: any) => state.builder.activeComponent);
    const selectedItemsId = useSelector((state: any) => state.builder.selectedItemsId);
    const duplicateKeyIds = useSelector((state: any) => state.builder.duplicateKeyIds);
    const activeComponent = useMemo(() => selectComponentById(store.getState(), activeComponentId), [activeComponentId]);
    const builderSettings = useSelector(selectBuilderSettings);
    const { isSyncTreeWithCanvasEnabled, openStates } = builderSettings;
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [dropLine, setDropLine] = useState<DIRECTION>("middle");
    const [contextMenuPosition, setContextMenuPosition] = useState<{ top: number; left: number } | null>(null);
    const theme = useTheme();
    const open = Boolean(anchorEl);
    const rootRef = useRef(null);
    const prevDropLine = useRef<DIRECTION>("middle");
    const isSelected =
      selectedItemsId?.length > 0 ? selectedItemsId?.includes(componentItem?.id) : componentItem?.id === activeComponent?.id;
    const isDuplicate = duplicateKeyIds?.length > 0 ? duplicateKeyIds?.includes(componentItem?.id) : false;
    const isActive = activeComponent?.id === componentItem?.id;
    const greedyComponent =
      componentItem?.type === "FlexContainer" ||
      componentItem?.type === "GridContainer" ||
      componentItem?.type === "CustomContainer" ||
      componentItem?.type === "StepperContainer";
    const showEmptyMessage = greedyComponent && (!componentItem?.children || componentItem?.children?.length === 0);
    const [{ isDragging: dragging }, drag] = useDrag({
      type: "Component",
      canDrag: !componentItem?.isModalComponent && !componentItem?.isCustomCanvas,
      item: () => {
        return {
          id: componentItem?.id,
          parentId: componentItem?.parentId,
          selectedItemsId,
          type: componentItem?.type,
        };
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop<any, void, { isOver: boolean; isOverCurrent: boolean; canDrop: boolean }>({
      accept: "Component",
      drop(item, monitor) {
        if (monitor.didDrop()) {
          return;
        }

        const draggedIds = item.selectedItemsId?.length ? item.selectedItemsId : [item.id];
        const draggedParentId = item.parentId;

        // Only allow dropping inside greedy components when they have children
        if (!greedyComponent && dropLine === "middle") {
          return;
        }

        moveBlocks(draggedIds, componentItem?.id, dropLine);
      },
      hover(item, monitor) {
        if (!rootRef.current || !monitor.isOver({ shallow: true })) {
          return;
        }
        const hoverBoundingRect = (rootRef as any).current.getBoundingClientRect();
        const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
        const buffer = hoverHeight * 0.2;
        const clientOffset = monitor.getClientOffset();

        if (!clientOffset) {
          return;
        }

        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        const draggedParentId = item.parentId;
        const isSameParent = draggedParentId === componentItem?.id;

        let newDropLine: DIRECTION = prevDropLine.current;

        // For container components
        if (greedyComponent) {
          const containerBuffer = hoverHeight * 0.3;

          // If it's from a different container, prioritize middle drop
          if (!isSameParent) {
            if (hoverClientY >= containerBuffer && hoverClientY <= hoverHeight - containerBuffer) {
              newDropLine = "middle";
            } else if (hoverClientY < containerBuffer) {
              newDropLine = "top";
            } else {
              newDropLine = "bottom";
            }
          } else {
            // For same container
            if (hoverClientY < buffer) {
              newDropLine = "top";
            } else if (hoverClientY > hoverHeight - buffer) {
              newDropLine = "bottom";
            } else {
              newDropLine = "middle";
            }
          }
        } else {
          // For non-containers
          if (hoverClientY < buffer) {
            newDropLine = "top";
          } else if (hoverClientY > hoverHeight - buffer) {
            newDropLine = "bottom";
          } else {
            newDropLine = "middle";
          }
        }

        // Only update if the drop line has changed
        if (newDropLine !== prevDropLine.current) {
          prevDropLine.current = newDropLine;
          setDropLine(newDropLine);
        }
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    });

    // Reset drop line when drag ends
    useEffect(() => {
      if (!isOver) {
        setDropLine("middle");
        prevDropLine.current = "middle";
      }
    }, [isOver]);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = useCallback(
      (componentItem: any) => (event: React.MouseEvent) => {
        const isMultiSelectKey = event?.shiftKey || event?.ctrlKey || event?.metaKey;

        if (!isMultiSelectKey) {
          handleSelectComponent(componentItem);
          return;
        } else {
          handleSelectComponentSingleAndMulti(componentItem, event);
        }
      },
      [handleSelectComponent, handleSelectComponentSingleAndMulti]
    );

    const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      // Select the component if it's not already selected
      if (!isSelected && handleSelectComponent) {
        handleSelectComponent(componentItem);
      }
      setContextMenuPosition({ top: event.clientY, left: event.clientX });
      setAnchorEl(event.currentTarget);
    };

    const handleAccordionToggle = () => {
      handleToggleOpen(componentItem.id);
    };

    useEffect(() => {
      if (isActive && rootRef.current && isSyncTreeWithCanvasEnabled) {
        const element = rootRef.current as HTMLElement;
        const scrollableContainer = element.closest(".component-scroll-container") as HTMLElement;
        if (scrollableContainer) {
          const elementRect = element.getBoundingClientRect();
          const containerRect = scrollableContainer.getBoundingClientRect();
          const scrollTop = elementRect.top - containerRect.top + scrollableContainer.scrollTop;
          scrollableContainer.scrollTo({
            top: scrollTop,
            behavior: "smooth",
          });
        }
      }
    }, [isActive, activeComponent]);

    useEffect(() => {
      if (isSelected && isCanvasClicked) {
        dispatch(setViewBuilder({ selectedItemsId: [componentItem.id], addToHistory: false }));
        setIsCanvasClicked(false);
      }
    }, [isSelected, isCanvasClicked]);

    let backgroundColor = theme.palette.background.paper;
    let border = "none";
    let dropIndicator: React.ReactNode = null;

    // Show drop target indicators
    if (isOverCurrent && canDrop) {
      if (dropLine === "middle" && greedyComponent) {
        border = `1px dashed ${theme.palette.primary.main}`;
      } else if (dropLine === "top" || dropLine === "bottom") {
        dropIndicator = (
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              height: "2px",
              backgroundColor: theme.palette.primary.main,
              [dropLine]: "-1px",
            }}
          />
        );
      }
    }

    const isCurrentDropTarget = isOverCurrent && dropLine;
    return (
      <div
        ref={mergeRefs([rootRef, drag, drop])}
        style={{
          position: "relative",
          backgroundColor,
          border,
          borderRadius: "4px",
          transition: "all 0.2s ease",
        }}
      >
        {dropIndicator}
        {isDuplicate && (
          <div
            style={{
              position: "absolute",
              right: -5,
              top: 0,
              width: "10px",
              height: "10px",
              backgroundColor: "red",
              borderRadius: "50%",
            }}
          />
        )}
        {componentItem?.props?.isMapValues && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: isDuplicate ? 15 : 5,
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          >
            <IconLine size={16} />
          </div>
        )}

        <Grid
          container
          xs={12}
          alignItems='center'
          sx={{
            ...componentsTreeItemStyles.container,
            maxHeight: "42px",
            width: "100%",
            "&:hover": {
              backgroundColor: !isSelected ? "rgba(210, 215, 231, 0.25)" : "",
            },
            backgroundColor: isSelected ? theme.palette.primary.light : "rgba(210, 215, 231, 0.10)",
          }}
          onClick={handleClick(componentItem)}
          onContextMenu={handleContextMenu}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {(componentItem?.type === "FlexContainer" ||
            componentItem?.type === "GridContainer" ||
            componentItem?.type === "StepperContainer" ||
            componentItem?.type === "CustomContainer") && (
            <Grid
              item
              xs={1}
              style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", cursor: "pointer" }}
              onClick={event => {
                event.stopPropagation();
                handleAccordionToggle();
              }}
            >
              <IconCaretDownFilled
                style={{
                  color: theme?.palette?.primary[200],
                  transform: isOpen ? "rotate(360deg)" : "rotate(270deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </Grid>
          )}

          <Grid item xs={10}>
            <Stack direction='row' spacing={0} alignItems='center'>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    border: `1px solid ${theme.palette.primary[200]}`,
                    borderRadius: ".8vh",
                    width: "40px",
                    height: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginInlineEnd: "8px",
                  }}
                >
                  {componentItem?.config?.placeholderConfig?.image && (
                    <img
                      src={componentItem?.config?.placeholderConfig?.image}
                      alt={componentItem?.type || ""}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  )}
                </Box>
              </Grid>
              <Typography
                sx={{
                  color: theme?.palette?.primary[200],
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant='subtitle1'
              >
                {componentItem?.type === "Typography" ? componentItem?.props?.children : componentItem?.props?.key}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              onClick={handleContextMenu}
              sx={{
                cursor: "pointer",
                display: isHovered ? "block" : "none",
              }}
            >
              <IconDotsVertical size={20} style={{ color: theme?.palette?.primary[200] }} />
            </Box>
          </Grid>
        </Grid>
        {isOpen && showEmptyMessage && (
          <Box
            sx={{
              paddingLeft: "20px",
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
          >
            <Typography sx={{ fontSize: "10px", color: theme?.palette?.primary[200] }}>This Container is empty.</Typography>
          </Box>
        )}
        {isOpen && filteredChildren && (
          <Box
            sx={{
              paddingLeft: "8px",
              borderLeft: `1px solid ${theme.palette.divider}`,
              marginLeft: "4px",
            }}
          >
            {filteredChildren?.map((child: any, childIndex: number) => (
              <ComponentsTreeItem
                key={childIndex}
                componentItemId={child}
                handleDeleteItem={handleDeleteItem}
                activeComponentId={activeComponentId}
                handleCopyItems={handleCopyItems}
                handleSelectComponentSingleAndMulti={handleSelectComponentSingleAndMulti}
                handlePasteItems={handlePasteItems}
                view={view}
                selectedItemsId={selectedItemsId}
                handleSelectComponent={handleSelectComponent}
                isOpen={openStates[child]}
                handleToggleOpen={handleToggleOpen}
                copyItemsRef={copyItemsRef}
                level={level + 1}
              />
            ))}
          </Box>
        )}
        <ActionPopover
          open={open}
          onClose={handleClose}
          handleDeleteItem={handleDeleteItem}
          handleCopyItems={handleCopyItems}
          handlePasteItems={handlePasteItems}
          children={memoizedChildren}
          activeComponentId={activeComponentId}
          selectedItemsId={selectedItemsId}
          contextMenuPosition={contextMenuPosition}
          hideDragActions={true}
          copyItemsRef={copyItemsRef}
        />

        {isCurrentDropTarget && canDrop && (dropLine === "top" || dropLine === "bottom") && (
          <div
            style={{
              backgroundColor,
              width: "100%",
              height: 3,
              position: "absolute",
              [dropLine]: 0,
              left: 0,
            }}
          />
        )}
      </div>
    );
  }
);

export default memo(ComponentsTreeItem);
